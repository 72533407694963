import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { 
    userLoginReducer, 
    userRegisterReducer,
    userProfileReducer,
    userHistoryReducer,
    userIpReducer
} from "./reducers/userReducers";
import {
    plateDetailsReducer,
    plateReactReducer,
    plateCommentReducer
} from "./reducers/plateReducers";

const reducer = combineReducers({
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userProfile: userProfileReducer,
    userHistory: userHistoryReducer,
    plateDetails: plateDetailsReducer,
    plateReact: plateReactReducer,
    plateComment: plateCommentReducer,
    userIp: userIpReducer
})

const userInfoFromStorage = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

const initialState = {
    userLogin: { userInfo: userInfoFromStorage},
}

const middleware = [thunk]

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store

    